<template>
    <b-container>
        <h1>Logimine vanast rahakoolist</h1>
    </b-container>
</template>

<script>
    export default {
        data () {
            return {
                email: '',
                password: ''
            }
        },
		created() {
        	this.loginFromOld();
		},
        methods: {
            loginFromOld () {
				axios.get('/login_from_old/'+this.$route.params.email+'/'+this.$route.params.token).then(({data}) => {
					this.$store
						.dispatch('setupUser', data)
						.then(() => {
							this.$router.push({ path: '/migrate' })
						})
						.catch(err => {
							console.log(err)
						})
				});
            },
        }
    }
</script>