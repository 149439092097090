<template>
    <b-container>
		<b-row>
			<b-col>
				<h1>Uuenda parooli</h1>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-alert v-if="error" show variant="danger"><em>{{ error }}</em></b-alert>
			</b-col>
		</b-row>
		<b-row>
			<b-col offset-md="30" class="text-center" sm="50" v-if="!changed">
				<b-overlay :show="spinner" rounded="sm">
					<b-form @submit.prevent="renewPassword">
						<passwords :password.sync="password"
								   :password2.sync="password2"
								   :errors.sync="passwordErrors"
						></passwords>
						<b-button @click="renewPassword"
								  variant="primary"
								  :disabled="passwordErrors"
						>
							Salvesta parool
						</b-button>
					</b-form>
				</b-overlay>
			</b-col>
		</b-row>
    </b-container>
</template>

<script>
    import Swal from "sweetalert2";
	import axios from "axios";
	import Passwords from "@/components/Passwords";

	export default {
		components: {
			Passwords
		},
        data () {
            return {
				spinner: false,
                password: '',
                password2: '',
				passwordErrors: false,
				error: false,
				changed: false,
            }
        },

        methods: {
            renewPassword () {
            	this.error = false;
				this.spinner = true;
                this.$store
                    .dispatch('resetPassword', {
                        email: this.$route.params.email,
						token: this.$route.params.token,
						password: this.password,
						password2: this.password2,
                    })
                    .then(() => {
						this.spinner = false;
						this.error = false;
						this.changed = true;
						Swal.fire({
							title: 'Parool muudetud!',
							confirmButtonText: 'Jätka',
							confirmButtonColor: '#6f42c1',
							icon: 'success',
						}).then((result) => {
							if (result.isConfirmed) {
								this.$router.push({ path: '/transactions' });
							}
						});
                    })
                    .catch(err => {
						this.spinner = false;
                        this.loginError = 'Viga: Sellise kasutajanime ja parooliga kasutajat ei leitud!';
                    })
            },
        }
    }
</script>
